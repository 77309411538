<template>
  <div class="common_page_footer">
    <div class="footer_panel">
      <div class="logo_content">
        <img src="./images/footerlogo.png">
      </div>
      <div class="footer_main">
        <div class="main_l">
          <div class="main_l_item" v-for="item in addressInfos" :key="item.name">
            <div class="main_l_item_label">
              {{ item.name }}
            </div>
            <div class="main_l_item_value">
              {{ item.address }}
            </div>
          </div>
        </div>
        <div class="main_r">
          <div class="pic_ewm">
            <img src="./images/ewm@mobile.png">
          </div>
          <div class="pic_ewm">
            <img src="./images/ewm@wx.png">
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="footer_bottom_left">
          {{copyright}}
        </div>
        <div class="footer_bottom_right">
          <div class="footer_bottom_link">网站地图</div>
          <div class="footer_bottom_link">联系我们</div>
        </div>
      </div>
    </div>
    <div class="division_line"></div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  props:{
    isActiveIn:{
      type:Boolean,
      default:false
    },
    effect:{
      type:String,
      default:"dark"
    }
  },
  // 定义属性
  data() {
    return {
      "addressInfos":[],
      "communicationInfos":[],
      "copyright":""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=companyFooterL`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.addressInfos = configData.addressInfos;
          _this.communicationInfos = configData.communicationInfos;
          _this.copyright = configData.copyright;
        }else{
          _this.$notify({
            title: '失败',
            message: "页脚数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.common_page_footer{
  height: 550pxpx;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #0086D1;
  position: relative;
  .footer_panel{
    min-width: 1200px;
    max-width: 1520px;
    width: calc(100vw - 10px);
    height: 100%;
    display: flex;
    flex-direction: column;
    .logo_content{
      height: 58px;
      margin-top: 40px;
      img{
        height: 100%;
      }
    }
    .footer_main{
      flex: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      color: #FFFFFF;
      padding: 10px 0;
      .main_l{
        display: flex;
        flex-wrap: wrap;
        .main_l_item{
          width: 50%;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          padding-right: 10px;
          box-sizing: border-box;
          &:last-child{
            margin-bottom: 0;
          }
          .main_l_item_label{
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
      .main_c{
        .main_c_item{
          margin-bottom: 34px;
          &:last-child{
            margin-bottom: 0;
          }
          i{
            margin-right: 10px;
          }
        }
      }
      .main_r{
        display: flex;
        margin-top: 21px;
        .pic_ewm{
          margin-right: 30px;
          width: 180px;
          height: 230px;
          &:last-child{
            margin-right: 0;
          }
          img{
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
        }
      }
    }
    .footer_bottom{
      height: 60px;
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      color: #FFFFFF;
      align-items: center;
      .footer_bottom_left{
        flex: 1;
        overflow: hidden;
      }
      .footer_bottom_right{
        display: flex;
        height: 16px;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        &::before{
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          left: calc(50% - 0.5px);
          pointer-events: none;
          background: #FFFFFF;
        }
        .footer_bottom_link{
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
  }
  .division_line{
    position: absolute;
    height: 1px;
    width: 100%;
    background: #FFFFFF;
    bottom: 61px;
    left: 0;
  }
}
</style>