<template>
  <div class="common_sys_header" :class="[{
    'common_sys_header_light':effect==='light'
  }]">
    <div class="header_panel" :class="[{
      'animate__animated':!isActiveIn
    },{
      'animate__fadeInDown':!isActiveIn
    }]" v-if="isActive">
      <div class="header_label">
        <div class="header_icon" @click="goPageFn('home')"></div>
      </div>
      <div class="menu_wrapper">
        <ul class="nav">
          <!-- S 菜单 -->
          <li class="home_btn" @click="goPageFn('home')">
            <div class="nav_a">
              <span>网站首页</span>
            </div>
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li>
            <div class="nav_a" @click="goPageFn('companyIntroduction')">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>关于赛赋</span>
            </div>
            <div class="subMenu">
              <span class="subMenu_a" @click="goPageFn('companyIntroduction')">
                <strong>公司简介</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('corporateCulture')">
                <strong>企业文化</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('developmentHistory')">
                <strong>发展历程</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('honoraryQualifications')">
                <strong>荣誉资质</strong>
              </span>
            </div>
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li>
            <div class="nav_a" @click="goPageFn('zczx')">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>赛赋服务</span>
            </div>
            <div class="subMenu">
              <span class="subMenu_a" @click="goPageFn('zczx')">
                <strong>研发咨询与注册申报</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('ktywfx')">
                <strong>抗体药物发现</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('sxjcyxpj')">
                <strong>早期药物筛选及成药性评价</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('ylyxxpj')">
                <strong>药理药效学评价</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('ywdxdlpj')">
                <strong>药物代谢动力评价</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('flcaqxpj')">
                <strong>非临床安全性评价</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('lcjc')">
                <strong>中心实验室服务与伴随诊断产品研发</strong>
              </span> 
              <span class="subMenu_a" @click="goPageFn('zbfw')">
                <strong>总包服务</strong>
              </span> 
            </div>
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li>
            <div class="nav_a" @click="goPageFn('cpzx')">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>产品中心</span>
            </div>
            <!-- <div class="subMenu">
              <span class="subMenu_a" @click="goPageFn('dbkt')">
                <strong>蛋白抗体</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('xbcp')">
                <strong>细胞产品</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('xq')">
                <strong>血清</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('otherProducts')">
                <strong>其他产品</strong>
              </span>
            </div> -->
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li>
            <div class="nav_a" @click="goPageFn('sfts')">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>赛赋特色</span>
            </div>
            <div class="subMenu">
              <span class="subMenu_a" @click="goPageFn('sfts')">
                <strong>赛赋特色</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('xrywyzsfwpt')">
                <strong>吸入药物一站式服务平台</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('jssjywyzsfwpt')">
                <strong>精神神经药物一站式服务平台</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('ykywyzsfwpt')">
                <strong>眼科药物一站式服务平台</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('adc')">
                <strong>放射性药物一体化平台</strong>
              </span>
            </div>
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li class="home_btn" @click="goPageFn('gsgg')">
            <div class="nav_a">
              <span>新闻资讯</span>
            </div>
          </li>
          <!-- E 菜单 -->
          <!-- S 菜单 -->
          <li>
            <div class="nav_a" @click="goPageFn('swhz')">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>联系我们</span>
            </div>
            <div class="subMenu">
              <span class="subMenu_a" @click="goPageFn('swhz')">
                <strong>商务合作</strong>
              </span>
              <span class="subMenu_a" @click="goPageFn('jrwm')">
                <strong>加入我们</strong>
              </span>
            </div>
          </li>
          <!-- E 菜单 -->
          <li>
            <div class="nav_a" @click="jumpEn">
              <div class="nav_a_bg">
                <div class="nav_a_bg_line"></div>
              </div>
              <span>{{ navLabel }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  props:{
    isActiveIn:{
      type:Boolean,
      default:false
    },
    effect:{
      type:String,
      default:"dark"
    }
  },
  // 定义属性
  data() {
    let langType = window.localStorage.getItem("locale");
    return {
      langType:langType||'zh',// 系统语音
      isActive:this.isActiveIn,
      navLabel:"",
      navUrl:""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 路由跳转
    goPageFn(name){
      let _this = this;
      if(_this.$route.name===name){
        _this.$EventBus.$emit("resetRoute");
        return
      }
      _this.$router.push({ 
        name: name,
      })
    },
    jumpEn(){
      window.open(this.navUrl)
    },
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=nav`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.navLabel = configData.navLabel
          _this.navUrl = configData.navUrl
        }else{
          _this.$notify({
            title: '失败',
            message: "轮播图数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 国际化类型
    changeType(type){
      this.langType = type;
      //此处做了语言选择记录
      localStorage.setItem('locale',type);
      //修改显示语言
      this.$i18n.locale = type;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.changeType(this.langType);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.common_sys_header{
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0);
  transition: all 0.5s ease-in-out;
  color: #000000;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  &.common_sys_header_light{
    background: rgba(255,255,255,1);
    box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.1);
  }
  &:hover{
    background: rgba(255,255,255,1);
    color: #000000;
    .header_panel{
      .header_label{
        .header_icon{
          background: url('./images/logo_light.png') 0 0 no-repeat;
          background-size: auto 100%;
        }
      }
    }
  }
  .header_panel{
    height: 100%;
    min-width: 1200px;
    width: 1520px;
    display: flex;
    align-items: center;
    justify-content: space-between ;
    .header_label{
      transition: all 0.5s ease;
      font-size: 38px;
      font-weight: bold;
      font-family:"Microsoft YaHei";
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .header_icon{
        height: 45px;
        width: 181px;
        flex: 1;
        overflow: hidden;
        background: url('./images/logo_dark.png') 0 0 no-repeat;
        background-size: auto 100%;
        cursor: pointer;
      }
    }
    .menu_wrapper{
      display: flex;
      .nav{
        margin-left: 60px;
        li{
          display: inline-block;
          padding: 0 20px;
          position: relative;
          list-style: none;
          // &::after{
          //   content: "|";
          //   position: absolute;
          //   right: -1px;
          //   top: 41px;
          //   transition: all 0.5s ease;
          // }
          // &:last-child{
          //   padding-right: 0;
          //   &::after{
          //     display: none;
          //   }
          // }
          &.home_btn{
            cursor: pointer;
            // padding: 0;
            // font-size: 12px;
            position: relative;
            .nav_a{
              .nav_a_bg{
                .nav_a_bg_line{
                  opacity: 0;
                }
              }
            }
          }
          &.language_change_btn{
            cursor: pointer;
            // padding: 0;
            font-size: 12px;
            position: relative;
            .nav_a{
              .nav_a_bg{
                .nav_a_bg_line{
                  opacity: 0;
                }
              }
            }
          }
          &:hover{
            .nav_a, .nav_a.on{
              color: #2057C7;
              // border-bottom: 2px solid #2057C7;
              .nav_a_bg{
                .nav_a_bg_line{
                  width: 100%;
                }
              }
            }
            .subMenu{
              opacity: 1;
              visibility: visible;
              transform: scaleY(1);
            }
          }
        }
        .nav_a{
          transition: all 0.5s ease;
          display: inline-block;
          line-height: 100px;
          font-size: 18px;
          letter-spacing: 1px;
          position: relative;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          .nav_a_bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            pointer-events: none;
            .nav_a_bg_line{
              width: 0;
              height: 2px;
              transition: all 0.5s ease;
              background: #2057C7;
            }
          }
        }
        .subMenu{
          position: absolute;
          left: 50%;
          margin-left: -100px;
          min-width: 200px;
          top: 100px;
          text-align: center;
          background: #fff;
          opacity: 0;
          visibility: hidden;
          padding: 12px 12px;
          transition: all 0.15s ease-in-out;
          transform: scaleY(0);
          transform-origin: center top;
          .subMenu_a{
            color: #333;
            text-decoration: none;
            outline: 0;
            transition: all 0.5s ease;
            display: block;
            position: relative;
            line-height: 46px;
            cursor: pointer;
            strong{
              font-size: 18px;
              display: block;
              font-weight: normal;
              white-space: nowrap;
            }
            &:hover{
              background: #2057C7;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}
@keyframes myAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>