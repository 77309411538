<template>
  <div class="common_page_footer">
    <div class="company_info_detail">
      <div class="company_info_detail_wrapper">
        <div class="cd_left">
          <div class="cd_left_icon"></div>
          <!-- <div class="cd_left_text">
            {{ $t('menuPage.companyDesc') }}
          </div> -->
          <div class="cd_left_media">
            <img src="./images/gzh.png" style="width:90px;">
          </div>
        </div>
        <div class="cd_r">
          <!-- <div class="cd_header">{{ $t('menu.contactUs') }}</div> -->
          <div class="cd_list">
            <!-- <div class="cd_i" v-for="item in concatInfos" :key="item.value">
              <div class="cd_i_i" :class="[{
                'connect_icon_1':item.type==1
              },{
                'connect_icon_2':item.type==2
              }]"></div>
              <div class="cd_i_v">
                {{item.value}}
              </div>
            </div> -->

            <div class="cd_i" v-for="item in addressInfos" :key="item.name">
              <div class="cd_i_i connect_icon_4"></div>
              <div class="cd_i_v">
                <p>
                  {{item.name}}
                </p>
                <p>
                  {{item.address}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company_info">
      <div class="company_info_content">
        <div class="company_info_desc">
          <p>{{copyRight}} </p>
          <p v-for="item in recordNumber" :key="item" @click="jumpToDeatail">
            {{item}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  props:{
    isActiveIn:{
      type:Boolean,
      default:false
    },
    effect:{
      type:String,
      default:"dark"
    }
  },
  components: {
  },
  // 定义属性
  data() {
    return {
      "concatInfos":[],
      "addressInfos":[],
      "copyRight":"",
      "recordNumber":[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 路由跳转
    goPageFn(name){
      let _this = this;
      _this.$router.push({ 
        name: name,
      })
    },
    jumpToDeatail(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=companyFooterS`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.concatInfos = configData.concatInfos;
          _this.addressInfos = configData.addressInfos;
          _this.copyRight = configData.copyRight;
          _this.recordNumber = configData.recordNumber;
        }else{
          _this.$notify({
            title: '失败',
            message: "页脚数据数据请求失败",
            type: 'error'
          });
        }
      });
    },
    
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.common_page_footer{
  background-size: cover;
  background-size: 100% 100%;
  background: #0086D1;
}
.company_info_desc {
  margin: 10px 0;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 0.6rem;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
  p:nth-of-type(2):hover {
    // color: #FFFFFF;
    cursor: pointer;
  }
  p:nth-of-type(3):hover {
    // color: #FFFFFF;
    cursor: pointer;
  }
  
}
  .company_info_detail{
    width: 100%;
    color: #FFFFFF;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .company_info_detail_wrapper{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 5vw;
      box-sizing: border-box;
      width: 100%;
      margin: 10px 0;
      .cd_left{
        flex: 1;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0;
        .cd_left_icon{
          height: 45px;
          background: url('./images/footerlogo.png') 0 0 no-repeat;
          background-size: auto 100%;
          // margin-bottom: 35px;
          flex: 1;
        }
        .cd_left_text{
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 1rem;
          // color: #8E92A3;s
          color: #FFFFFF;
          line-height: 1.4rem;
          letter-spacing: 1px;
        }
        .cd_left_media{
          width: 90px;
          .cd_left_media_item{
            height: 48px;
            width: 48px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            // background:  rgba(44,63,94,0.7);
            // border-radius: 24px;
            margin-right: 26px;
            &:last-child{
              margin-right: 0;
            }
            &.icon_1{
              background: url("../images/share_icon_1.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.icon_2{
              background: url("../images/share_icon_2.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.icon_3{
              background: url("../images/share_icon_3.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.icon_4{
              background: url("../images/share_icon_4.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      .cd_c{
        margin: 0 60px;
        width: 160px;
      }
      .cd_r{
        width: 100%;
      }
      .cd_header{
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 1.2rem;
        color: #FFFFFF;
        line-height: 48px;
        letter-spacing: 2px;
        margin-bottom: 12px;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          width: 48px;
          height: 2px;
          background: #3FB1FF;
          bottom: -2px;
          left: 0;
        }
      }
      .cd_list{
        display: flex;
        flex-direction: column;
        .cd_i{
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
          display: flex;
          .cd_i_i{
            margin-right: 12px;
            width: 20px;
            height: 20px;
            &.connect_icon_1{
              background: url("../images/connect_icon_1.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.connect_icon_2{
              background: url("../images/connect_icon_2.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.connect_icon_3{
              background: url("../images/connect_icon_3.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
            &.connect_icon_4{
              margin-right: 12px;
              // width: 30px;
              height: 20px;
              background: url("../images/connect_icon_4.png") 0 0 no-repeat;
              background-size: 100% 100%;
            }
          }
          .cd_i_v{
            flex: 1;
            p{
              &:first-child{
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
  .company_info{
    // height: 75px;
    width: 100%;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #8E92A3;
    line-height: 1.4rem;
    // background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    border-top: 1px solid #16314D;
    padding: 0 5vw;
    box-sizing: border-box;
    .company_info_content{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .company_info_list{
      display: flex;
      .company_info_li{
        padding: 0 20px;
        border-left: 1px solid rgba(255,255,255,0.6);
        cursor: pointer;
        &:hover{
          color: #FFFFFF;
        }
        &:first-child{
          border-left: none;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
</style>